const business = [
    '#9147ff26',
    '#9147ff2a',
    '#DEC8FF',
    '#D3B5FF',
    '#C8A3FF',
    '#BD91FF',
    '#B27EFF',
    '#A76CFF',
    '#9C59FF',
    '#9147FF',
    '#8340E6',
    '#7439CC',
    '#6632B3',
    '#572B99',
    '#492480',
    '#3A1C66',
    '#2B154C',
    '#1D0E33',
    '#0E0719'
]

const gray = [
    '#f5f5f5', //lightness50,  // light dom buy sell border
    '#f5f5f5', //lightness100, // light list first (internal) borders + hover list
    '#eeeeee', //lightness150, // light seconnd borders main
    '#e5e5e5', //lightness200, // light border take profit (fixed by variable --themed-color-control-intent-default) // dark main color text
    '#bdbdbd', //lightness250, // dark hover text in list options settings
    '#9e9e9e', //lightness300, // dark black color of number in chart // light text in buy sell
    '#bdbdbd', //lightness350, // light input search placeholder search symbol
    '#9e9e9e', //lightness400, // light market close color text
    '#bdbdbd', //lightness450, // dark color of table headers text
    '#757575', //lightness550, // light list head (setting list)
    '#757575', //lightness550, // light color of table headers text
    '#757575', //lightness600, // dark market close color
    '#616161', //lightness650, // dark sell buy btn color + border take profit
    '#555555', //lightness700, // dark secondary borders on table and delimeters
    '#424242', //lightness750, // dark secondary borders on widget list
    '#3F3F3F', //lightness800, // dark main borders and dark list hover
    '#2A2A2A', //lightness850, // dark popup background
    '#121212', //lightness900, // dark bg // light color text
    '#000000', //lightness950  // dark bg hover for modal
]

const red = [
    '#FEECEB',
    '#FDD9D7',
    '#FCC7C3',
    '#FBB4AF',
    '#FAA19B',
    '#F88E86',
    '#F77B72',
    '#F6695E',
    '#F5564A',
    '#F44336',
    '#DC3C31',
    '#C3362B',
    '#AB2F26',
    '#922820',
    '#7A221B',
    '#621B16',
    '#491410',
    '#310D0B',
    '#180705'
]

const green = [
    '#E6F5F3',
    '#CCEAE7',
    '#B3E0DB',
    '#99D5CF',
    '#80CBC4',
    '#66C0B8',
    '#4DB6AC',
    '#33ABA0',
    '#1AA194',
    '#009688',
    '#00877A',
    '#00786D',
    '#00695F',
    '#005A52',
    '#004B44',
    '#003C36',
    '#002D29',
    '#001E1B',
    '#000F0E'
]

const blue = [
    '#E6F6FE',
    '#CDEEFD',
    '#B3E5FC',
    '#9ADDFB',
    '#81D4FA',
    '#68CBF8',
    '#4FC3F7',
    '#35BAF6',
    '#1CB2F5',
    '#03A9F4',
    '#0398DC',
    '#0287C3',
    '#0276AB',
    '#026592',
    '#02557A',
    '#014462',
    '#013349',
    '#012231',
    '#001118'
]

const yellow = [
    '#FFFDEB',
    '#FFFBD8',
    '#FFF9C4',
    '#FFF7B1',
    '#FFF59D',
    '#FFF389',
    '#FFF176',
    '#FFEF62',
    '#FFED4F',
    '#FFEB3B',
    '#E6D435',
    '#CCBC2F',
    '#B3A529',
    '#998D23',
    '#80761E',
    '#665E18',
    '#4C4612',
    '#332F0C',
    '#191706'
]

const orange = [
    '#FFF5E6',
    '#FFEACC',
    '#FFE0B3',
    '#FFD699',
    '#FFCC80',
    '#FFC166',
    '#FFB74D',
    '#FFAD33',
    '#FFA21A',
    '#FF9800',
    '#E68900',
    '#CC7A00',
    '#B36A00',
    '#995B00',
    '#804C00',
    '#663D00',
    '#4C2E00',
    '#331E00',
    '#190F00'
]

export const light = {
    'color1': [
        ...business
    ],
    'color2': [
        ...gray
    ],
    'color3': [
        ...red
    ],
    'color4': [
        ...green
    ],
    'color5': [
        ...orange
    ],
    'color6': [
        ...blue
    ],
    'color7': [
        ...yellow
    ],
    white: '#ffffff',
    black: 'inherit',
}

export const dark = {
    'color1': [
        ...business
    ],
    'color2': [
        ...gray
    ],
    'color3': [
        ...red
    ],
    'color4': [
        ...green
    ],
    'color5': [
        ...orange
    ],
    'color6': [
        ...blue
    ],
    'color7': [
        ...yellow
    ],
    white: 'inherit',
    black: 'inherit',
}









