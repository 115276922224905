import {defaultConfig, IConfig} from "../../../context/config"
import {IAuth} from "../../../context/auth"


export class WebSocketsManager {
    private socket: WebSocket | null = null
    private socketReconnectAttempts: number = 0
    private socketReconnectTimeoutId: number | null = null
    private applicationConfiguration: IConfig = defaultConfig
    public static instance: WebSocketsManager | null = null
    private contextAuth!: IAuth
    private subscribersTicks: Set<Function> = new Set()
    private subscribersTradeEvents: Set<Function> = new Set()
    private messageBufferTicks: any[] = []
    private MAX_BUFFER_SIZE_TICKS = 500
    private manualDisconnect: boolean = false
    private workerInstance: Worker | null = null

    constructor(applicationConfiguration: IConfig = defaultConfig, contextAuth: IAuth) {
        this.applicationConfiguration = applicationConfiguration
        this.contextAuth = contextAuth
        this.connect()
        this.startNotificationLoopTicks()
    }

    static getInstance(applicationConfiguration: IConfig = defaultConfig, contextAuth: IAuth): WebSocketsManager {
        if (!WebSocketsManager.instance) {
            WebSocketsManager.instance = new WebSocketsManager(applicationConfiguration, contextAuth)
        }
        return WebSocketsManager.instance
    }

    subscribeTradeEvents(subscriber: Function): void {
        this.subscribersTradeEvents.add(subscriber)
    }

    subscribeTicks(subscriber: Function): void {
        this.subscribersTicks.add(subscriber)
    }

    unsubscribeTicks(subscriber: Function): void {
        this.subscribersTicks.delete(subscriber)
    }

    unsubscribeTradeEvents(subscriber: Function): void {
        this.subscribersTradeEvents.delete(subscriber)
    }

    sendCommandJson(command: string, payload: Record<string, any> = {}): void {
        if (!this.socket || this.socket.readyState !== WebSocket.OPEN) {
            console.error("WebSocket is not open. Cannot send command.")
            return
        }
        const message = JSON.stringify({command, payload})
        this.socket.send(message)
    }

    private startNotificationLoopTicks() {
        if (this.workerInstance) return
        this.workerInstance = new Worker(new URL('../../../assets/utils/workerLoopEngine.ts', import.meta.url), {type: 'module'})
        this.workerInstance.onmessage = (e: MessageEvent) => {
            this.notifySubscribersTicks()
        }
        this.workerInstance.postMessage({
            command: 'start',
            intervalMs: 16
        })
    }

    private stopNotificationLoopTicksInWorker() {
        if (!this.workerInstance) return
        this.workerInstance.postMessage({ command: 'stop' })
        this.workerInstance.terminate()
        this.workerInstance = null
    }

    private notifySubscribersTicks(): void {
        if (!this.messageBufferTicks.length) return
        for (const message of this.messageBufferTicks) {
            for (const subscriber of Array.from(this.subscribersTicks)) {
                subscriber(message)
            }
        }
        this.messageBufferTicks = []
    }

    static disconnect(): void {
        if (WebSocketsManager.instance) {
            WebSocketsManager.instance.disconnect()
            WebSocketsManager.instance = null
        }
    }

    private disconnect(): void {
        this.manualDisconnect = true
        if (this.socket) {
            this.socket.close()
            this.socket = null
        }
        if (this.socketReconnectTimeoutId) {
            clearTimeout(this.socketReconnectTimeoutId)
            this.socketReconnectTimeoutId = null
        }
        this.subscribersTicks.clear()
        this.subscribersTradeEvents.clear()
        this.messageBufferTicks = []
        this.stopNotificationLoopTicksInWorker()
    }

    private connect(): void {
        if (this.socket) return

        this.manualDisconnect = false
        this.socket = new WebSocket(`${this.applicationConfiguration.urlWebsocket}/api/v1/stream/${this.contextAuth.loginAccountId}` as string)

        this.socket.onopen = () => {
            this.socketReconnectAttempts = 0
        }

        this.socket.onmessage = async (event: MessageEvent) => {
            try {
                const data = JSON.parse(event.data)
                if (data.message_type === "TICK") {
                    if (this.subscribersTicks.size === 0) return
                    if (this.messageBufferTicks.length > this.MAX_BUFFER_SIZE_TICKS) this.messageBufferTicks.shift()
                    this.messageBufferTicks.push(data)
                    return
                }
                if (data.message_type === "TRADE") {
                    for (const subscriber of Array.from(this.subscribersTradeEvents)) {
                        await subscriber(data)
                    }
                    return
                }
            } catch (e) {
                if (event.data === "PING") return
                console.error("Socket onmessage error:", event, e)
            }
        }

        this.socket.onclose = () => {
            this.socket = null
            if (!this.manualDisconnect && this.socketReconnectAttempts < 20) {
                if (this.socketReconnectTimeoutId) {
                    clearTimeout(this.socketReconnectTimeoutId)
                }
                this.socketReconnectTimeoutId = window.setTimeout(() => this.connect(), 3500)
                this.socketReconnectAttempts++
            }
        }

        this.socket.onerror = (error) => {
            console.error(error)
            this.socket = null
        }
    }
}