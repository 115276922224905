import { ISettingsAdapter, InitialSettingsMap } from "../../assets/lib/charting_library/charting_library"
import { IAuth } from "../../context/auth"
import { IConfig } from "../../context/config"
import { RemoteSettingsManager } from "../../settings/RemoteSettingsManager"

const WATHCLIST_KEY = 'watchlist'

export class SettingsAdapter implements ISettingsAdapter {
    private static _defaultAdapter: SettingsAdapter

    static async setUpDefaultAdapter(config: IConfig, authContext: IAuth): Promise<void> {
        const adapter = new SettingsAdapter(config, authContext)
        await adapter.loadRemoteSettings()
        this._defaultAdapter = adapter
    }

    static defaultAdapter() {
        if (!this._defaultAdapter) {
            throw new Error('SettingsAdapter not initialized')
        }
        return this._defaultAdapter
    }

    private watchlistSettingsCache: Record<string, any> = {}
    defaultSymbols?: string[]

    constructor(private config: IConfig, private authContext: IAuth) {}

    get initialSettings(): InitialSettingsMap {
        return {...this.getLocalSettings(), ...this.watchlistSettingsCache}
    }

    private async loadRemoteSettings(): Promise<void> {
        try {
            if (this.authContext.loginAccountId) {
                const setting = await RemoteSettingsManager.defaultManager().getValue(WATHCLIST_KEY, this.authContext.loginAccountId)
                const value = setting.value

                if (Array.isArray(value)) {
                    // nothing was stored before, the back end provides default watchlist contents
                    this.defaultSymbols = value as Array<string>
                } else if (typeof value === 'object') {
                    this.watchlistSettingsCache = value
                }
            }
        } catch (error) {
            // not critical. no-op
        }
    }

    private getLocalSettings(): Record<string, any> {
        return Object.entries(localStorage).reduce((accumulator, [key, value]) => {
            return { ...accumulator, [key]: value }
        }, {})
    }

    private shouldStoreRemotely(settingName: string): boolean {
        return settingName.includes('savedwatch') || settingName === 'widgetbar.widget.watchlistterminal'
    }

    private async postWatchlist(edition: number = 1) {
        if (this.authContext.loginAccountId) {
            try {
                await RemoteSettingsManager.defaultManager()
                    .setValue(WATHCLIST_KEY, this.authContext.loginAccountId, this.watchlistSettingsCache, edition)
            } catch (error) {
                // not critical. no-op
            }
        }
    }

    // ISettingsAdapter
    setValue(key: string, value: string): void {
        if (this.shouldStoreRemotely(key)) {
            this.watchlistSettingsCache[key] = value
            this.postWatchlist()
        } else {
            localStorage.setItem(key, value)
        }
    }

    removeValue(key: string): void {
        if (this.shouldStoreRemotely(key)) {
            this.watchlistSettingsCache[key] = undefined
            this.postWatchlist()
        } else {
            localStorage.removeItem(key)
        }
    }
}
