import React, {useEffect} from "react"
import {ConfigProvider} from "./context/config"
import {AuthProvider} from "./context/auth"
import {TradingViewWidget} from "./components/tradingView"
import {ToastContainer} from "react-toastify"
import {getThemeFromUrlOrStorage} from "./assets/utils/theme"

function App() {
    useEffect(() => {
        document.documentElement.setAttribute("data-bs-theme", getThemeFromUrlOrStorage());
    }, [])

    return (
        <ConfigProvider>
            <ToastContainer/>
            <AuthProvider>
                <TradingViewWidget/>
            </AuthProvider>
        </ConfigProvider>
    )
}

export default App
