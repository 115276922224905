type TLots = {
    lots: number;
};
type TContractSize = {
    contractSize: number;
};
type TLeverage = {
    leverage: number;
};
type TMarginRate = {
    marginRate: number;
};
type TInitialMargin = {
    initialMargin: number;
};
type TTickPrice = {
    tickPrice: number;
};
type TTickSize = {
    tickSize: number;
};
type TMarketPrice = {
    marketPrice: number;
};
type TLastPrice = {
    lastPrice: number;
};
type TMaintenanceMargin = {
    maintenanceMargin: number;
};
type TOpenPrice = {
    openPrice: number;
};
type TClosePrice = {
    closePrice: number;
};

// BUY 0 | SELL 1
type TTradeType = 1 | 0

function profitDefault(
    data: TOpenPrice & TClosePrice & TContractSize & TLots,
    tradeType: TTradeType = 0
): number {
    if (tradeType === 1) {
        return (data.openPrice - data.closePrice) * data.contractSize * data.lots
    }
    return (data.closePrice - data.openPrice) * data.contractSize * data.lots
}

function profitTick(
    data: TLots & TOpenPrice & TClosePrice & TTickPrice & TTickSize,
    tradeType: TTradeType = 0
): number {
    if (tradeType === 1) {
        return (data.openPrice - data.closePrice) * data.lots * data.tickPrice / data.tickSize
    }
    return (data.closePrice - data.openPrice) * data.lots * data.tickPrice / data.tickSize
}

function marginExchFutures(
    data: TLots & TInitialMargin & TMarginRate & TMaintenanceMargin
        | TLots & TMaintenanceMargin & TMarginRate & TInitialMargin
): number {
    const marginParam = data.initialMargin || data.maintenanceMargin
    return data.lots * marginParam * data.marginRate
}

function marginForexNoLeverage(data: TLots & TContractSize & TMarginRate): number {
    return data.lots * data.contractSize * data.marginRate
}

type TModuleCalculationFunctions = {
    profit: Record<number, Function>;
    margin: Record<number, Function>;
};

const functions: TModuleCalculationFunctions = {
    profit: {
        0: profitDefault,
        1: profitTick,
        2: profitDefault,
        3: profitDefault,
        4: profitDefault,
        5: profitDefault,
        32: profitDefault,
        33: profitTick,
        34: profitTick
    },
    margin: {
        0: function (data: TLots & TContractSize & TLeverage & TMarginRate): number {
            return (data.lots * data.contractSize) / data.leverage * data.marginRate
        },
        1: function (data: TLots & TInitialMargin & TMarginRate): number {
            return data.lots * data.initialMargin * data.marginRate
        },
        2: function (data: TLots & TContractSize & TMarginRate & TMarketPrice): number {
            return marginForexNoLeverage(data) * data.marketPrice
        },
        3: function (data: TLots & TContractSize & TMarketPrice & TTickPrice & TTickSize & TMarginRate): number {
            return (data.lots * data.contractSize * data.marketPrice * data.tickPrice) / data.tickSize * data.marginRate
        },
        4: function (data: TLots & TContractSize & TMarketPrice & TLeverage & TMarginRate): number {
            return (data.lots * data.contractSize * data.marketPrice) / data.leverage * data.marginRate
        },
        5: marginForexNoLeverage,
        32: function (data: TLots & TContractSize & TMarginRate & TLastPrice): number {
            return marginForexNoLeverage(data) * data.lastPrice
        },
        33: marginExchFutures,
        34: marginExchFutures
    }
}

export default functions