import React, {createContext, ReactNode, useContext, useEffect} from "react"
import {Login} from "../components/auth/Login"
import {Config} from "./config"
import "bootstrap/dist/js/bootstrap.bundle.min.js"
import {Flip, toast, ToastOptions} from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import {$fetch} from "../assets/utils/fetch"

export interface IAuth {
    loginAccountId: number | null;
    login: (login: number, password: string) => Promise<void>;
    logout: () => Promise<void>;
}

export const Auth = createContext({
    loginAccountId: null,
    login: (login: number, password: string) => {
    },
    logout: () => {
    }
} as IAuth)

export const AuthProvider: React.FC<{ children: ReactNode }> = ({
                                                                    children
                                                                }: {
    children: ReactNode;
}) => {
    const config = useContext(Config)
    const [isAuthenticated, setIsAuthenticated] = React.useState(false)
    const [loginAccountId, setLoginAccountId] = React.useState<number | null>(
        null
    )
    const [isLoading, setIsLoading] = React.useState(true)

    useEffect(() => {
        const runAsync = async () => {
            await verify()
        }
        runAsync()
    }, [])

    const login = async (login: number, password: string) => {
        try {
            const response = await $fetch.post(
                `${config.urlRestAPI}/api/v1/auth/login`,
                {
                    body: JSON.stringify({
                        login,
                        password
                    })
                }
            )
            if (!response.ok) {
                response.status === 401
                    ? runErrorToast("Invalid login or password")
                    : runErrorToast(`Error: ${response.statusText}`)
                return
            }
            await verify()
        } catch (e: any) {
            if (e?.code === 429 && e?.context?.retry_after && e?.context?.limit) {
                runErrorToast(
                    (<p>
                        You have exceeded the login attempt limit ({e.context.limit} attempts).
                        Please try again in {e.context.retry_after} seconds. If you forgot your
                        password, reset it in your&nbsp;
                        <a target="_blank" href="https://gtly.to/zqhwvauHYK">
                            Client Portal
                        </a>.
                    </p>),
                    {
                        autoClose: false
                    }
                )
                return
            }
            runErrorToast(e.message || "Error 500")
        }

        function runErrorToast(err: any, options?: ToastOptions) {
            // Default options for the toast
            const defaultOptions: ToastOptions = {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: 0,
                theme: "light",
                transition: Flip
            }
            const finalOptions = {...defaultOptions, ...options}
            toast.error(err, finalOptions)
        }
    }

    const logout = async () => {
        await $fetch.delete(`${config.urlRestAPI}/api/v1/auth/logout`)
        await verify()
    }

    const verify = async () => {
        try {
            const response = await $fetch.get(
                `${config.urlRestAPI}/api/v1/auth/verify`
            )
            const data = await response.json()
            setLoginAccountId(data.login)
            setIsAuthenticated(data.success)
        } catch (e) {
            setIsAuthenticated(false)
        } finally {
            setIsLoading(false)
        }
    }

    if (isLoading) return <></>

    return (
        <Auth.Provider
            value={
                {
                    login,
                    logout,
                    loginAccountId
                } as IAuth
            }
        >
            {isAuthenticated ? children : <Login/>}
        </Auth.Provider>
    )
}