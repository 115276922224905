const business = [
    "#f5f3ff", //50,
    "#ede9fe", //100,
    "#fff000", //150,
    "#ddd6ff", //200,
    "#fff000", //250,
    "#c4b4ff", //300,
    "#fff000", //350,
    "#a684ff", //400,
    "#fff000", //450,
    "#8e51ff", //500,
    "#fff000", //550,
    "#7f22fe", //600,
    "#fff000", //650,
    "#7008e7", //700,
    "#fff000", //750,
    "#5d0ec0", //800,
    "#fff000", //850,
    "#4d179a", //900,
    "#2f0d68" //950,
]

const grayLight = [
    "#f3f4f6", //50,  //light dom buy sell border
    "#f3f4f6", //100, //light list first (internal) borders + hover list
    "#e5e7eb", //150, //light second borders main
    "#d4d8df", //200, //light border / border take profit
    "inherit", //250,
    "#d4d8df", //300, //border hover settings checkbox
    "#d4d8df", //350, //light input search placeholder search symbol
    "#99a1af", //400, //light market close color text
    "inherit", //450,
    "#6a7282", //500, //light list head (setting list left bar options)
    "#6a7282", //550, //light color of table headers text
    "#6a7282", //600, //light border color in tooltip
    "inherit", //650,
    "inherit", //700,
    "inherit", //750,
    "#4a5565", //800, //light tooltip bg
    "inherit", //850,
    "#131823", //900, //light color text
    "#030712", //950,
]

const grayDark = [
    "inherit", //50,
    "#d4d8df", //100,
    "inherit", //150,
    "#d4d8df", //200, //dark text color
    "#d4d8df", //250, //dark hover text in list options settings
    "#99a1af", //300, //dark black color of number in chart
    "#d4d8df", //350, //dark input search text
    "#99a1af", //400, //dark line in chart hover
    "#99a1af", //450, //dark color of table headers text
    "#99a1af", //500, //dark title text in list options settings
    "#99a1af", //550, //dark background border hover
    "#99a1af", //600, //dark market close color
    "#6a7282", //650, //dark sell buy btn color + border take profit + borders color
    "#364153", //700, //dark secondary borders on table and delimiter
    "#364153", //750, //dark secondary borders on widget list + hover tabs in search
    "#2a3646", //800, //dark main borders and list hover
    "#1e2939", //850, //dark bg popup
    "#131823", //900, //dark bg color
    "#030712", //950, //dark bg hover for modal
]

const red = [
    "#fef2f2", //50,
    "#ffe2e2", //100,
    "#f5f5f5", //150,
    "#ffc9c9", //200,
    "#f5f5f5", //250,
    "#ffa2a2", //300,
    "#f5f5f5", //350,
    "#ff6467", //400,
    "#f5f5f5", //450,
    "#fb2c36", //500,
    "#f5f5f5", //550,
    "#e7000b", //600,
    "#f5f5f5", //650,
    "#c10007", //700,
    "#f5f5f5", //750,
    "#9f0712", //800,
    "#f5f5f5", //850,
    "#82181a", //900,
    "#460809" //950,
]

const green = [
    "#ecfdf5", //50,
    "#d0fae5", //100,
    "#f5f5f5", //150,
    "#a4f4cf", //200,
    "#f5f5f5", //250,
    "#5ee9b5", //300,
    "#f5f5f5", //350,
    "#00d492", //400,
    "#f5f5f5", //450,
    "#00bc7d", //500,
    "#f5f5f5", //550,
    "#009966", //600,
    "#f5f5f5", //650,
    "#007a55", //700,
    "#f5f5f5", //750,
    "#006045", //800,
    "#f5f5f5", //850,
    "#004f3b", //900,
    "#002c22" //950,
]

const blue = [
    "#eff6ff", //50,
    "#dbeafe", //100,
    "#f5f5f5", //150,
    "#bfdbfe", //200,
    "#f5f5f5", //250,
    "#93c5fd", //300,
    "#f5f5f5", //350,
    "#60a5fa", //400,
    "#f5f5f5", //450,
    "#3b82f6", //500,
    "#f5f5f5", //550,
    "#2563eb", //600,
    "#f5f5f5", //650,
    "#1d4ed8", //700,
    "#f5f5f5", //750,
    "#1e40af", //800,
    "#f5f5f5", //850,
    "#1e3a8a", //900,
    "#172554" //950,
]

const yellow = [
    "#fefce8", //50,
    "#fef9c3", //100,
    "#f5f5f5", //150,
    "#fef08a", //200,
    "#f5f5f5", //250,
    "#fde047", //300,
    "#f5f5f5", //350,
    "#facc15", //400,
    "#f5f5f5", //450,
    "#eab308", //500,
    "#f5f5f5", //550,
    "#ca8a04", //600,
    "#f5f5f5", //650,
    "#facc15", //700, //light star color
    "#f5f5f5", //750,
    "#eab308", //800, //light star color
    "#f5f5f5", //850,
    "#713f12", //900,
    "#422006" //950,
]

const orange = [
    "#fff7ed", //50,
    "#ffedd5", //100,
    "#f5f5f5", //150,
    "#fed7aa", //200,
    "#f5f5f5", //250,
    "#fdba74", //300,
    "#f5f5f5", //350,
    "#fb923c", //400,
    "#f5f5f5", //450,
    "#f97316", //500,
    "#f5f5f5", //550,
    "#ea580c", //600,
    "#f5f5f5", //650,
    "#c2410c", //700,
    "#f5f5f5", //750,
    "#9a3412", //800,
    "#f5f5f5", //850,
    "#7c2d12", //900,
    "#431407" //950,
]

export const light = {
    "color1": [
        ...business
    ],
    "color2": [
        ...grayLight
    ],
    "color3": [
        ...red
    ],
    "color4": [
        ...green
    ],
    "color5": [
        ...orange
    ],
    "color6": [
        ...blue
    ],
    "color7": [
        ...yellow
    ],
    white: "#ffffff",
    black: "inherit"
}

export const dark = {
    "color1": [
        ...business
    ],
    "color2": [
        ...grayDark
    ],
    "color3": [
        ...red
    ],
    "color4": [
        ...green
    ],
    "color5": [
        ...orange
    ],
    "color6": [
        ...blue
    ],
    "color7": [
        ...yellow
    ],
    white: "inherit",
    black: "inherit"
}









