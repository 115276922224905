import styles from "./Login.module.scss"
import logoLight from "../../assets/images/logo-light.svg"
import logoDark from "../../assets/images/logo-dark.svg"
import React, {useCallback, useContext, useState} from "react"
import {Auth} from "../../context/auth"
import {getThemeFromUrlOrStorage} from "../../assets/utils/theme"
import {getParameterByName} from "../../assets/utils/query"

export const Login = () => {
    const contextAuth = useContext(Auth)

    const [login, setLogin] = useState("")
    const [password, setPassword] = useState("")
    const [isDebounceActive, setIsDebounceActive] = useState(false);
    const isButtonDisabled = login.trim() === "" || password.trim() === ""
    const isThemeLight = getThemeFromUrlOrStorage() === "light"
    const logoSrc = isThemeLight ? logoLight : logoDark
    const dontHavaRegistrationDescription = getParameterByName("hasreg") === "false"

    const authenticate = useCallback(() => {
        contextAuth.login(
            +login,
            password
        )
    }, [login, password, contextAuth])

    const handleClick = () => {
        if (!isButtonDisabled) {
          setIsDebounceActive(true);
          authenticate();

          setTimeout(() => {
            setIsDebounceActive(false);
          }, 2000);
        }
    };

    return (
        <div className={styles.video}>
            <video
                className={styles.video__background}
                src={isThemeLight ? require("../../assets/images/logo-background-light.mp4") : require("../../assets/images/logo-background-dark.mp4")}
                autoPlay
                muted
                loop
                playsInline
            >
            </video>
            <div className={styles.login}>
                <div>
                    <div className={styles.login__card + " bg-body p-5 shadow"}>
                        <img height={"68px"} src={logoSrc}
                             className={styles.login__logo + " rounded mx-auto d-block mb-5"}
                             alt="..."/>
                        <div className="mb-3">
                            <input type="number" onChange={(e) => setLogin(e.target.value)}
                                   className="form-control form-control-lg" placeholder="Login ID"/>
                        </div>
                        <div className="mb-5">

                            <input type="password" onChange={(e) => setPassword(e.target.value)}
                                   onKeyUp={(event) => {if (event.key === 'Enter') {authenticate()}}}
                                   className="form-control form-control-lg" placeholder="Password"/>
                        </div>
                        <div className={styles.login__buttonContainer}>
                            <button type="button" disabled={isButtonDisabled || isDebounceActive}
                                    className={styles.login__button + " btn btn-primary form-control btn-lg"}
                                    onClick={handleClick}>Log In
                            </button>
                        </div>
                        {!dontHavaRegistrationDescription && (
                            <div className={"text-center mt-5"}>
                                <div>Don't have a trading account yet?</div>
                                <div>
                                    <a target="_blank" href="https://gtly.ink/ecgq3br0_?redirectUrl=%2F&amp;lang=en">Click
                                        here</a>
                                    &nbsp; to register.
                                </div>
                            </div>
                        )}
                    </div>

                </div>
            </div>
        </div>
    )
}