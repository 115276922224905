import {getParameterByName} from "./query"

export type ThemeName = "light" | "dark";

export const getThemeFromUrlOrStorage = (): ThemeName => {
    const removeThemeFromUrl = () => {
        const url = new URL(window.location.href)
        url.searchParams.delete("theme")
        window.history.replaceState({}, document.title, url.pathname + url.search)
    }
    const urlTheme = getParameterByName("theme") as ThemeName

    if (urlTheme && (urlTheme === "dark" || urlTheme === "light")) {
        localStorage.setItem("theme", urlTheme)
        document.documentElement.setAttribute("data-bs-theme", urlTheme)
        removeThemeFromUrl()
        return urlTheme
    }
    return <ThemeName>localStorage.getItem("theme") || "light"
}