import { v4 as createUUID } from 'uuid'
import {
    ChartData,
    ChartMetaInfo,
    ChartTemplate,
    ChartTemplateContent,
    IExternalSaveLoadAdapter,
    LineToolsAndGroupsLoadRequestContext,
    LineToolsAndGroupsLoadRequestType,
    LineToolsAndGroupsState,
    StudyTemplateData,
    StudyTemplateMetaInfo,
} from '../../assets/lib/charting_library/charting_library'
import { RemoteSettingsManager } from '../../settings/RemoteSettingsManager'

interface CompoundChartContents {
    charts: Record<string, ChartData>
    studyTemplates: Record<string, StudyTemplateData>
    drawingTemplates: Record<string, string>
    chartTemplates: Record<string, ChartTemplateContent>
    drawings: Record<string, any>
}

const CHART_CONTENTS_KEY = 'chart_contents'
export class SaveLoadAdapter implements IExternalSaveLoadAdapter, CompoundChartContents {
    charts: Record<string, ChartData> = {}
    studyTemplates: Record<string, StudyTemplateData> = {}
    drawingTemplates: Record<string, string> = {}
    chartTemplates: Record<string, ChartTemplateContent> = {}
    drawings: Record<string, any> = {}
    remoteContentsLoaded = false

    constructor(private login: number) {}

    static async adapterForLogin(login: number): Promise<SaveLoadAdapter> {
        const adapter = new SaveLoadAdapter(login)
        await adapter.loadFromBackEndIfNeeded()
        return adapter
    }

    private async loadFromBackEndIfNeeded() {
        if (!this.remoteContentsLoaded) {
            try {
                const { value } = await RemoteSettingsManager.defaultManager().getValue(
                    CHART_CONTENTS_KEY,
                    this.login
                )

                if (typeof value === 'object' && !Array.isArray(value)) {
                    const contents = value as unknown as CompoundChartContents
                    this.charts = contents.charts || {}
                    this.studyTemplates = contents.studyTemplates || []
                    this.drawingTemplates = contents.drawingTemplates || []
                    this.chartTemplates = contents.chartTemplates || []
                    this.drawings = contents.drawings || {}
                }
            } catch (error) {
                // just carry on with defaults
            }

            this.remoteContentsLoaded = true
        }
    }

    private async saveToBackEnd() {
        const contents: CompoundChartContents = {
            charts: this.charts,
            studyTemplates: this.studyTemplates,
            drawingTemplates: this.drawingTemplates,
            chartTemplates: this.chartTemplates,
            drawings: this.drawings,
        }

        try {
            await RemoteSettingsManager.defaultManager().setValue(
                CHART_CONTENTS_KEY,
                this.login,
                contents
            )
        } catch (error) {
            // At least we tried
        }
    }

    // --- charts {
    async getAllCharts(): Promise<ChartMetaInfo[]> {
        const allChartsList = Object.values(this.charts)
            .filter((chartInfo) => !!chartInfo.id)
            .map((input) => {
                const result = {
                    id: input.id!,
                    name: input.name,
                    symbol: input.symbol,
                    resolution: input.resolution,
                    timestamp: input.timestamp,
                }
                return result
            })
        return Promise.resolve(allChartsList)
    }

    async removeChart(id: string): Promise<void> {
        delete this.charts[id]
        await this.saveToBackEnd()
    }

    async saveChart(chartData: ChartData): Promise<string | number> {
        const savedChartData = {
            ...chartData,
            id: chartData.id || createUUID(),
            timestamp: Math.round(Date.now() / 1000),
        }

        this.charts[savedChartData.id] = savedChartData
        await this.saveToBackEnd()

        return savedChartData.id
    }

    async getChartContent(chartId: number | string): Promise<string> {
        const content = this.charts[chartId].content
        if (!content) {
            throw new Error(`Chart ${chartId} not found`)
        }
        return content
    }

    // --- charts }

    async removeStudyTemplate(studyTemplateInfo: StudyTemplateMetaInfo): Promise<void> {
        delete this.studyTemplates[studyTemplateInfo.name]

        await this.saveToBackEnd()
    }

    async getStudyTemplateContent(studyTemplateInfo: StudyTemplateMetaInfo): Promise<string> {
        const template = this.studyTemplates[studyTemplateInfo.name]
        if (!template) {
            throw new Error(`Study template ${studyTemplateInfo.name} not found`)
        }

        return template.content
    }

    async saveStudyTemplate(studyTemplateData: StudyTemplateData): Promise<void> {
        this.studyTemplates[studyTemplateData.name] = studyTemplateData

        await this.saveToBackEnd()
    }

    async getAllStudyTemplates(): Promise<StudyTemplateMetaInfo[]> {
        return Object.values(this.studyTemplates)
    }

    async removeDrawingTemplate(toolName: string, templateName: string): Promise<void> {
        delete this.drawingTemplates[this._getDrawingTemplateKey(toolName, templateName)]
        await this.saveToBackEnd()
    }

    async loadDrawingTemplate(toolName: string, templateName: string): Promise<string> {
        const key = this._getDrawingTemplateKey(toolName, templateName)

        const template = this.drawingTemplates[key]
        if (!template) {
            throw new Error(`Drawing template template ${key} not found`)
        }

        return template
    }

    async saveDrawingTemplate(
        toolName: string,
        templateName: string,
        content: string
    ): Promise<void> {
        this.drawingTemplates[this._getDrawingTemplateKey(toolName, templateName)] = content
        await this.saveToBackEnd()
    }

    async getDrawingTemplates(toolName: string): Promise<string[]> {
        return Object.keys(this.drawingTemplates)
            .map((key) => key.split('/'))
            .filter((components) => components.length == 2 && components[0] === toolName)
            .map((components) => components[1])
    }

    _getDrawingTemplateKey(toolName: string, templateName: string): string {
        return `${toolName}/${templateName}`
    }

    async getAllChartTemplates(): Promise<string[]> {
        return Object.keys(this.chartTemplates)
    }

    async saveChartTemplate(newName: string, content: ChartTemplateContent): Promise<void> {
        this.chartTemplates[newName] = content
        await this.saveToBackEnd()
    }

    async removeChartTemplate(templateName: string): Promise<void> {
        delete this.chartTemplates[templateName]
        await this.saveToBackEnd()
    }

    async getChartTemplateContent(templateName: string): Promise<ChartTemplate> {
        let result: ChartTemplate = {}

        const template = this.chartTemplates[templateName]
        if (template) {
            result.content = structuredClone(template)
        }

        return result
    }

    // required if the `saveload_separate_drawings_storage` featureset is enabled
    async saveLineToolsAndGroups(
        layoutId: string | undefined,
        chartId: string | number,
        state: LineToolsAndGroupsState
    ): Promise<void> {
        const drawings = state.sources
        const key = this._getDrawingKey(layoutId, chartId)

        if (!this.drawings[key]) {
            this.drawings[key] = {}
        }

        for (let [id, state] of drawings || []) {
            if (state === null) {
                delete this.drawings[key][id]
            } else {
                this.drawings[key][id] = state
            }
        }

        await this.saveToBackEnd()
    }

    // required if the `saveload_separate_drawings_storage` featureset is enabled
    async loadLineToolsAndGroups(
        layoutId: string | undefined,
        chartId: string | number,
        _requestType: LineToolsAndGroupsLoadRequestType,
        _requestContext: LineToolsAndGroupsLoadRequestContext
    ): Promise<Partial<LineToolsAndGroupsState> | null> {
        const rawSources = this.drawings[this._getDrawingKey(layoutId, chartId)]
        if (!rawSources) return null
        const sources = new Map()

        for (let [key, state] of Object.entries(rawSources)) {
            sources.set(key, state)
        }
        return {
            sources,
        }
    }

    _getDrawingKey(layoutId: string | undefined, chartId: string | number): string {
        return `${layoutId || ''}/${chartId}`
    }
}
