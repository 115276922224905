import {GenericError} from "./GenericError"
import {generateUUIDv4} from "./UUID"

const initBasic = {
    credentials: "include"
} as RequestInit

const headersStandard = {
    "Content-Type": "application/json"
}

const handleErrors = async (
    response: Response,
    method: string
): Promise<void> => {
    if (!response.ok) {
        const error = await response.json()
        console.error({
            error,
            response
        })

        throw new GenericError(
            error.detail,
            error.code || response.status,
            error.context,
            error.stack
        )
    }
}

export const $fetch = {
    get: async (
        input: RequestInfo | URL,
        init?: RequestInit
    ): Promise<Response> => {
        const response = await fetch(input, {
            ...init,
            ...initBasic,
            method: "GET",
            headers: {
                ...init?.headers,
                ...headersStandard,
                "Cache-Control": "no-cache, no-store, must-revalidate",
                "X-Trace-ID": generateUUIDv4()
            }
        })

        await handleErrors(response, "POST")
        return response
    },
    post: async (
        input: RequestInfo | URL,
        init?: RequestInit
    ): Promise<Response> => {
        const response = await fetch(input, {
            ...init,
            ...initBasic,
            method: "POST",
            headers: {
                ...init?.headers,
                ...headersStandard,
                "X-Trace-ID": generateUUIDv4()
            }
        })

        await handleErrors(response, "POST")
        return response
    },
    patch: async (
        input: RequestInfo | URL,
        init?: RequestInit
    ): Promise<Response> => {
        const response = await fetch(input, {
            ...init,
            ...initBasic,
            method: "PATCH",
            headers: {
                ...init?.headers,
                ...headersStandard,
                "X-Trace-ID": generateUUIDv4()
            }
        })

        await handleErrors(response, "POST")
        return response
    },
    delete: async (
        input: RequestInfo | URL,
        init?: RequestInit
    ): Promise<Response> => {
        const response = await fetch(input, {
            ...init,
            ...initBasic,
            method: "DELETE",
            headers: {
                ...init?.headers,
                ...headersStandard,
                "X-Trace-ID": generateUUIDv4()
            }
        })

        await handleErrors(response, "POST")
        return response
    }
}
