import { useContext } from 'react'
import { Config } from '../../context/config'
import styles from './CalculatorWrapper.module.scss'

export const CalculatorWrapper = ({ theme }: any) => {
    const config = useContext(Config)

    return (
        <div className={ styles.calculator_wrapper }>
            <iframe src={ config.urlCalculator + `?theme=${ theme }&fontSize=14px` }></iframe>
        </div>
    )
}