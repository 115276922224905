import { $fetch } from "../assets/utils/fetch"

const PSEUDO_REMOTE_STORAGE_KEY = 'pseudoremotestorage'

export interface Setting {
    name: string
    value?: string | number| Record<string, unknown> | Array<unknown>
    edition: number
    created_at: string
    updated_at: string
}

export class RemoteSettingsManager {
    private static _defaultManager: RemoteSettingsManager

    static defaultManager() {
        if (!this._defaultManager) {
            const shouldBypass = process.env.REACT_APP_ALL_SETTINGS_LOCAL === 'true'
            this._defaultManager = new RemoteSettingsManager(process.env.REACT_APP_URL_RESTAPI || '', shouldBypass)
        }
        return this._defaultManager
    }

    constructor(private apiURL: string, private bypassRemoteStorage: boolean = false) {}

    async getValue(name: string, login: number): Promise<Setting> {
        if (this.bypassRemoteStorage) {
            const value = this.localStorageContainer()[name]
            if (value === undefined) {
                throw new Error(`Couldn't find locally stored: ${name}`)
            } else {
                return {
                    value,
                    name,
                    created_at: new Date().toISOString(),
                    updated_at: new Date().toISOString(),
                    edition: 1,
                }
            }
        } else {
            const response = await $fetch.get(this.urlForSetting(name, login))
            if (response.ok) {
                return await response.json()
            } else {
                const body = await response.json();
                throw new Error(`Couldn't get settings: ${name} for account ${login}, response: ${response.status}: ${JSON.stringify(body)}`)
            }
        }
    }

    async setValue(name: string, login: number, value: unknown, edition: number = 1) {
        const payload = {
            value,
            edition,
        }

        if (this.bypassRemoteStorage) {
            const updatedContainer = {...this.localStorageContainer(), [name]: value}
            localStorage.setItem(PSEUDO_REMOTE_STORAGE_KEY, JSON.stringify(updatedContainer))
        } else {
            await $fetch.post(this.urlForSetting(name, login), { body: JSON.stringify(payload) })
        }
        
    }

    private urlForSetting(settingName: string, login: number): string {
        return `${this.apiURL}/api/v1/settings/${login}/${settingName}`
    }

    private localStorageContainer(): Record<string, any> {
        const rawData = localStorage.getItem(PSEUDO_REMOTE_STORAGE_KEY)

        let result: Record<string, any> = {}

        if (rawData) {
            try {
                const parsedData = JSON.parse(rawData)

                if (typeof parsedData === 'object') {
                    result = parsedData
                }
            } catch (error) {
                // ok to return {}
            }
        }

        return result
    }
}
