import React, {ReactNode, HTMLAttributes} from "react"
import styles from "./Sidebar.module.scss"
import "bootstrap-icons/font/bootstrap-icons.css"

interface SideBarProps extends HTMLAttributes<HTMLElement> {
    title?: string;
    closeCallback?: () => void;
    children: ReactNode;
}

export const SideBarWrapper: React.FC<SideBarProps> = ({
                                                           title,
                                                           closeCallback,
                                                           children,
                                                       }) => {
    return (
        <div
            className={styles.sidebarWrapper + ` d-flex flex-column`}
        >
            <nav
                className={`
                    navbar navbar-expand-lg 
                    sticky-top 
                    ${styles.navbarCustom}
                `}
            >
                <div className="container-fluid">
                    <span className={` ${styles.navbarCustom_title} navbar-brand mb-0`}>{title}</span>
                    <button
                        className="btn btn-sm btn-outline-primary"
                        onClick={closeCallback}
                        aria-label="Close"
                    >
                        <i className="bi bi-x"></i>
                    </button>
                </div>
            </nav>
            <div className={`flex-grow-1 overflow-auto`}>
                {children}
            </div>
        </div>
    )
}