import {useEffect, useRef, useState} from "react"

export function useSleepCheckWorkerLoopEngine(
    callback: any
) {
    const [, setLastTimeToCheckSleep] = useState<number>(Date.now())
    const timerWorkerRef = useRef<Worker | null>(null)

    useEffect(() => {
        timerWorkerRef.current = new Worker(
            new URL("../../../assets/utils/workerLoopEngine.ts", import.meta.url),
            {type: "module"}
        )

        timerWorkerRef.current.onmessage = () => {
            const now = Date.now()
            setLastTimeToCheckSleep((prevTime) => {
                const diff = now - prevTime
                if (diff >= 5000) {
                    console.log(`Tab was not active ~${diff} ms`)
                    callback()
                }
                return now
            })
        }

        timerWorkerRef.current.postMessage({
            command: "start",
            intervalMs: 1000
        })

        return () => {
            if (timerWorkerRef.current) {
                timerWorkerRef.current.postMessage({command: "stop"})
                timerWorkerRef.current.terminate()
                timerWorkerRef.current = null
            }
        }
    }, [])
}
